<div class="form-title mt-3 mb-3">
    Interviewer Contact Information
</div>
<div class="form-data" [formGroup]="contactInformationForm">
  <div class="form-group">
    <label for="phoneNumber" class="required">Interviewer Phone Number</label>
    <input type="text" class="form-input" id="phoneNumber" formControlName="phoneNumber">
  </div>
  <div class="form-group">
    <label for="interviewerEmail" class="required">Interviewer Email</label>
    <input type="text" class="form-input" id="interviewerEmail" formControlName="interviewerEmail">
  </div>
  <div class="form-group">
    <label for="interviewerFacebook" class="required">Interviewer Facebook URL</label>
    <input type="text" class="form-input" id="interviewerFacebook" formControlName="interviewerFacebook">
  </div>
  <div class="form-group">
    <label for="interviewerTwitter" class="required">Interviewer Twitter Handle</label>
    <input type="text" class="form-input" id="interviewerTwitter" formControlName="interviewerTwitter">
  </div>
  <div class="form-group">
    <label for="interviewerInstagram" class="required">Interviewer Instagram Handle</label>
    <input type="text" class="form-input" id="interviewerInstagram" formControlName="interviewerInstagram">
  </div>
  <div class="form-group">
    <label for="interviewerLinkedin" class="required">Inteviewer LinkedIn Profile</label>
    <input type="text" class="form-input" id="interviewerLinkedin" formControlName="interviewerLinkedin">
  </div>
  <div class="form-group">
    <label for="interviewerTiktok" class="required">Interviewer Tiktok Profile</label>
    <input type="text" class="form-input" id="interviewerTiktok" formControlName="interviewerTiktok">
  </div>
  <div class="form-title mb-3" style="margin:40px 0">
    Camera Person Contact Information
  </div>
  <div class="form-group">
    <label for="cameraEmail">Camera Person Email</label>
    <input type="text" class="form-input" id="cameraEmail" formControlName="cameraEmail">
  </div>
  <div class="form-group">
    <label for="cameraFacebook" class="required">Camera Person Facebook URL</label>
    <input type="text" class="form-input" id="cameraFacebook" formControlName="cameraFacebook">
  </div>
  <div class="form-group">
    <label for="cameraTwitter" class="required">Camera Person Twitter Handle</label>
    <input type="text" class="form-input" id="cameraTwitter" formControlName="cameraTwitter">
  </div>
  <div class="form-group">
    <label for="cameraInstagram" class="required">Camera Person Instagram Handle</label>
    <input type="text" class="form-input" id="cameraInstagram" formControlName="cameraInstagram">
  </div>
  <div class="form-group">
    <label for="cameraLinkedin" class="required">Camera Person LinkedIn Profile</label>
    <input type="text" class="form-input" id="cameraLinkedin" formControlName="cameraLinkedin">
  </div>
  <div class="form-group">
    <label for="cameraTiktok" class="required">Camera Person Tiktok Profile</label>
    <input type="text" class="form-input" id="cameraTiktok" formControlName="cameraTiktok">
  </div>
  <div class="form-group">
    <label for="cameraPhone">Camera Person Phone</label>
    <input type="text" class="form-input" id="cameraPhone" formControlName="cameraPhone">
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'" class="save-button" (click)="saveForm()">Save</button>
</div>
