import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-narrator-contact-information',
  templateUrl: './narrator-contact-information.component.html',
  styleUrls: ['./narrator-contact-information.component.scss']
})
export class NarratorContactInformationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public narratorContactInformationForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.narratorContactInformationForm = this.fb.group({
      additionalInfo: [null]
    });
  }


  get userType() {
    return this.authService.getUserType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getNarratorContactInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.narratorContactInformationForm = this.fb.group({
            additionalInfo: [ information.additionalInfo ]
          });
        }
      });
    }
  }

  saveForm() {
    const additionalInfo = this.narratorContactInformationForm.controls['additionalInfo'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      additionalInfo
    };
    this.backendService.saveNarratorContactInformation(data)
      .subscribe(() => this.toastr.success('Narrator Contact Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
