import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interviewer-contact-information',
  templateUrl: './interviewer-contact-information.component.html',
  styleUrls: ['./interviewer-contact-information.component.scss']
})
export class InterviewerContactInformationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public contactInformationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.contactInformationForm = this.fb.group({
      phoneNumber: [null ],
      interviewerEmail: [null],
      interviewerFacebook: [null],
      interviewerTwitter: [null],
      interviewerInstagram: [null],
      interviewerLinkedin: [null],
      interviewerTiktok: [null],
      cameraEmail: [null ],
      cameraFacebook: [null],
      cameraTwitter: [null],
      cameraInstagram: [null],
      cameraLinkedin: [null],
      cameraTiktok: [null],
      cameraPhone: [null]
    });

    this.setValidators();
  }

  get userType() {
    return this.authService.getUserType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewerContactInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.contactInformationForm = this.fb.group({
            phoneNumber: [ information.phoneNumber ],
            interviewerEmail: [ information.interviewerEmail ],
            interviewerFacebook: [ information.interviewerFacebook ],
            interviewerTwitter: [ information.interviewerTwitter ],
            interviewerInstagram: [ information.interviewerInstagram ],
            interviewerLinkedin : [ information.interviewerLinkedin ],
            interviewerTiktok: [ information.interviewerTiktok ],
            cameraEmail: [information.cameraEmail ],
            cameraFacebook: [information.cameraFacebook],
            cameraTwitter: [information.cameraTwitter],
            cameraInstagram: [information.cameraInstagram],
            cameraLinkedin: [information.cameraLinkedin],
            cameraTiktok: [information.cameraTiktok],
            cameraPhone: [information.cameraPhone],
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.contactInformationForm.controls['phoneNumber'].setValidators(Validators.required);
      this.contactInformationForm.controls['phoneNumber'].updateValueAndValidity();
      this.contactInformationForm.controls['email'].setValidators(Validators.required);
      this.contactInformationForm.controls['email'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.contactInformationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const phoneNumber = this.contactInformationForm.controls['phoneNumber'].value;
    const interviewerEmail = this.contactInformationForm.controls['interviewerEmail'].value;
    const interviewerFacebook = this.contactInformationForm.controls['interviewerFacebook'].value;
    const interviewerTwitter = this.contactInformationForm.controls['interviewerTwitter'].value;
    const interviewerInstagram = this.contactInformationForm.controls['interviewerInstagram'].value;
    const interviewerLinkedin = this.contactInformationForm.controls['interviewerLinkedin'].value;
    const interviewerTiktok = this.contactInformationForm.controls['interviewerTiktok'].value;
    const cameraEmail = this.contactInformationForm.controls['cameraEmail'].value;
    const cameraFacebook = this.contactInformationForm.controls['cameraFacebook'].value;
    const cameraTwitter = this.contactInformationForm.controls['cameraTwitter'].value;
    const cameraInstagram = this.contactInformationForm.controls['cameraInstagram'].value;
    const cameraLinkedin = this.contactInformationForm.controls['cameraLinkedin'].value;
    const cameraTiktok = this.contactInformationForm.controls['cameraTiktok'].value;
    const cameraPhone = this.contactInformationForm.controls['cameraPhone'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      phoneNumber,
      interviewerEmail,
      interviewerFacebook,
      interviewerTwitter,
      interviewerInstagram,
      interviewerLinkedin,
      interviewerTiktok,
      cameraEmail,
      cameraFacebook,
      cameraTwitter,
      cameraInstagram,
      cameraLinkedin,
      cameraTiktok,
      cameraPhone
    };
    this.backendService.saveInterviewerContactInformation(data)
      .subscribe(() => this.toastr.success('Interviewer Contact Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

}
