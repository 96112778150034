<div class="form-title mt-3 mb-3">
  Red Flags
</div>
<div class="form-data" [formGroup]="otherForm">
  <div class="form-group">
    <div class="form-group">
      <label for="offensiveComments" class="required">Offensive Comments</label>
      <input type="text" class="form-input" id="offensiveComments" formControlName="offensiveComments">
    </div>
    <div class="form-group">
      <label for="politicalBias" class="required">Comments Based on Political Bias</label>
      <input type="text" class="form-input" id="politicalBias" formControlName="politicalBias">
    </div>
    <div class="form-group">
      <label for="religiousBias" class="required">Comments Based on Religious Bias</label>
      <input type="text" class="form-input" id="religiousBias" formControlName="religiousBias">
    </div>
    <div class="form-group">
      <label for="socioEconomicBias" class="required">Comments Based on Socio-Economic Bias</label>
      <input type="text" class="form-input" id="socioEconomicBias" formControlName="socioEconomicBias">
    </div>
    <div class="form-group">
      <label for="genderBias" class="required">Comments Based on Gender Bias</label>
      <input type="text" class="form-input" id="genderBias" formControlName="genderBias">
    </div>
    <div class="form-group">
      <label for="ethnicBias" class="required">Comments Based on Ethnic/Caste Bias</label>
      <input type="text" class="form-input" id="ethnicBias" formControlName="ethnicBias">
    </div>
    <div class="form-group">
      <label for="conformsToNorms" class="required">Comments or actions not in conformity to mainstream cultural norms</label>
      <input type="text" class="form-input" id="conformsToNorms" formControlName="conformsToNorms">
    </div>
    <div class="form-group">
      <label for="excessiveHearsay" class="required">Excessive Hearsay</label>
      <input type="text" class="form-input" id="excessiveHearsay" formControlName="excessiveHearsay">
    </div>
    <div class="form-group">
      <label for="hatefulAccusations" class="required">Hateful Accusations</label>
      <input type="text" class="form-input" id="hatefulAccusations" formControlName="hatefulAccusations">
    </div>
    <div class="form-group">
      <label for="incriminatoryDescriptions" class="required">Incriminatory Descriptions</label>
      <input type="text" class="form-input" id="incriminatoryDescriptions" formControlName="incriminatoryDescriptions">
    </div>
    <div class="form-group">
      <label for="swearing" class="required">Swearing</label>
      <input type="text" class="form-input" id="swearing" formControlName="swearing">
    </div>
    <div class="form-group">
      <label for="showingHostileBehavior" class="required">Showing Hostile Behavior</label>
      <input type="text" class="form-input" id="showingHostileBehavior" formControlName="showingHostileBehavior">
    </div>
    <div class="form-group">
      <label for="expressingMaliciousIntent" class="required">Expressing Malicious Intent</label>
      <input type="text" class="form-input" id="expressingMaliciousIntent" formControlName="expressingMaliciousIntent">
    </div>
    <div class="form-group">
      <label for="warStrategies" class="required">Mention of War Strategies/Secrets</label>
      <input type="text" class="form-input" id="warStrategies" formControlName="warStrategies">
    </div>
    <div class="form-group">
      <label for="politicalSecrets" class="required">Mention of Political Secrets</label>
      <input type="text" class="form-input" id="politicalSecrets" formControlName="politicalSecrets">
    </div>
    <div class="form-group">
      <label for="religiousConversionMention" class="required">Mention of Religious Conversion</label>
      <input type="text" class="form-input" id="religiousConversionMention" formControlName="religiousConversionMention">
    </div>
    <div class="form-group">
      <label for="concealedIdentities" class="required">Mention of Concealed Identities</label>
      <input type="text" class="form-input" id="concealedIdentities" formControlName="concealedIdentities">
    </div>
    <div class="form-group">
      <label for="lighting" class="required">Problem with Lighting</label>
      <input type="text" class="form-input" id="lighting" formControlName="lighting">
    </div>
    <div class="form-group">
      <label for="framing" class="required">Problem with Framing</label>
      <input type="text" class="form-input" id="framing" formControlName="framing">
    </div>
    <div class="form-group">
      <label for="sound" class="required">Problem with Sound</label>
      <input type="text" class="form-input" id="sound" formControlName="sound">
    </div>
    <div class="form-group">
      <label for="otherComments" class="required">Other comments</label>
      <input type="text" class="form-input" id="otherComments" formControlName="otherComments">
    </div>
    <div class="form-group">
      <label for="intendedAudience" class="required">Intended audience</label>
      <input type="text" class="form-input" id="intendedAudience" formControlName="intendedAudience">
    </div>
    <div class="form-group">
      <label for="groupsMentionedContact" class="required">Groups\/organizations mentioned during the interview contact info</label>
      <input type="text" class="form-input" id="groupsMentionedContact" formControlName="groupsMentionedContact">
    </div>
    <div class="form-group">
      <label for="groupsMentionedNames" class="required">Groups\/organizations mentioned during the interview names</label>
      <input type="text" class="form-input" id="groupsMentionedNames" formControlName="groupsMentionedNames">
    </div>
    <div class="form-group">
      <label for="subjects" class="required">Subjects discussed</label>
      <input type="text" class="form-input" id="subjects" formControlName="subjects">
    </div>
    <div class="form-group">
      <label for="topicalDateRange" class="required">Topical date range</label>
      <input type="text" class="form-input" id="topicalDateRange" formControlName="topicalDateRange">
    </div>
    <div class="form-group">
      <label for="communityRepresented" class="required">Community represented in the interview (separate multiple answers by commas - can be religious, social or otherwise)</label>
      <textarea id="communityRepresented" class="w-100 form-textarea" formControlName="communityRepresented" (paste)="onPaste($event, 'communityRepresented')"></textarea>
  </div>
    <label class="required">Reviewed?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="reviewed" value="Yes" id="yesReviewed" formControlName="reviewed">
      <label class="form-check-label" for="yesReviewed">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="reviewed" value="No" id="noReviewed" formControlName="reviewed">
      <label class="form-check-label" for="noReviewed">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="entryReviewNotes" class="required">Entry Review Notes</label>
    <textarea id="entryReviewNotes" class="w-100 form-textarea" formControlName="entryReviewNotes" (paste)="onPaste($event, 'entryReviewNotes')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"   class="save-button" (click)="saveForm()">Save</button>
</div>
