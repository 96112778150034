import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transcript-information',
  templateUrl: './transcript-information.component.html',
  styleUrls: ['./transcript-information.component.scss']
})
export class AppTranscriptInformationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public transcriptionInformationForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.transcriptionInformationForm = this.fb.group({
      transcriberName: [null],
      transcriberContactInfo: [null],
      transcript: [null],
      transcriptEditorName: [null],
      transcriptEditorContact: [null],
      audioVideoEditorName: [null],
      audioVideoEditorContactInfo: [null],
      captionerName: [null],
      captionerContactInfo: [null]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getTranscriptionInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.transcriptionInformationForm = this.fb.group({
            transcriberName: [information.transcriberName],
            transcriberContactInfo: [information.transcriberContactInfo],
            transcript: [information.transcript],
            transcriptEditorName: [information.transcriptEditorName],
            transcriptEditorContact: [information.transcriptEditorContact],
            audioVideoEditorName: [information.audioVideoEditorName],
            audioVideoEditorContactInfo: [information.audioVideoEditorContactInfo],
            captionerName: [information.captionerName],
            captionerContactInfo: [information.captionerContactInfo]
          });
        }
      });
    }
  }

  get userType() {
    return this.authService.getUserType();
  }

  saveForm() {
    const transcriberName = this.transcriptionInformationForm.controls['transcriberName'].value;
    const transcriberContactInfo = this.transcriptionInformationForm.controls['transcriberContactInfo'].value;
    const transcript = this.transcriptionInformationForm.controls['transcript'].value;
    const transcriptEditorName = this.transcriptionInformationForm.controls['transcriptEditorName'].value;
    const transcriptEditorContact = this.transcriptionInformationForm.controls['transcriptEditorContact'].value;
    const audioVideoEditorName = this.transcriptionInformationForm.controls['audioVideoEditorName'].value;
    const audioVideoEditorContactInfo = this.transcriptionInformationForm.controls['audioVideoEditorContactInfo'].value;
    const captionerName = this.transcriptionInformationForm.controls['captionerName'].value;
    const captionerContactInfo = this.transcriptionInformationForm.controls['captionerContactInfo'].value;
    const interviewID = this.interviewID;

    const data = {
      interviewID,
      transcriberName,
      transcriberContactInfo,
      transcript,
      transcriptEditorName,
      transcriptEditorContact,
      audioVideoEditorName,
      audioVideoEditorContactInfo,
      captionerName,
      captionerContactInfo,
    };
    this.backendService.saveTranscriptionInformation(data)
      .subscribe(() => this.toastr.success('Transcription Information', 'Data saved successfully'))
    this.formSaved.emit();
  }
}
