<div class="form-title mt-3 mb-3">
  Content
</div>
<div class="form-data" [formGroup]="contentForm">
  <div class="form-group">
    <label class="required">Did the interviewer adequately cover all of the application sections from the list of "Suggested Questions"?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="coverAllSuggestedQuestions" value="Yes" id="yesCoverAllSuggestedQuestions" formControlName="coverAllSuggestedQuestions">
      <label class="form-check-label" for="yesCoverAllSuggestedQuestions">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="coverAllSuggestedQuestions" value="No" id="noCoverAllSuggestedQuestions" formControlName="coverAllSuggestedQuestions">
      <label class="form-check-label" for="noCoverAllSuggestedQuestions">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewe's quesions encourage rich and detailed responses?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="richDetailResponse" value="Yes" id="yesRichDetailResponse" formControlName="richDetailResponse">
      <label class="form-check-label" for="yesRichDetailResponse">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="richDetailResponse" value="No" id="noRichDetailResponse" formControlName="richDetailResponse">
      <label class="form-check-label" for="noRichDetailResponse">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer form questions that built upon what the interviewee had said previously?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="formQuestions" value="Yes" id="yesFormQuestions" formControlName="formQuestions">
      <label class="form-check-label" for="yesFormQuestions">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="formQuestions" value="No" id="noFormQuestions" formControlName="formQuestions">
      <label class="form-check-label" for="noFormQuestions">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer encourage the interviewee to elaborate?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="encourageToElaborate" value="Yes" id="yesEncourageToElaborate" formControlName="encourageToElaborate">
      <label class="form-check-label" for="yesEncourageToElaborate">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="encourageToElaborate" value="No" id="noEncourageToElaborate" formControlName="encourageToElaborate">
      <label class="form-check-label" for="noEncourageToElaborate">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer seem unbiased?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="unbiased" value="Yes" id="yesUnbiased" formControlName="unbiased">
      <label class="form-check-label" for="yesUnbiased">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="unbiased" value="No" id="noUnbiased" formControlName="unbiased">
      <label class="form-check-label" for="noUnbiased">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer focus on documenting the remembrances that emerged naturally without introducing their own ideas and influencing the interviewee?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="focusOnRemembrances" value="Yes" id="yesFocusOnRemembrances" formControlName="focusOnRemembrances">
      <label class="form-check-label" for="yesFocusOnRemembrances">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="focusOnRemembrances" value="No" id="noFocusOnRemembrances" formControlName="focusOnRemembrances">
      <label class="form-check-label" for="noFocusOnRemembrances">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did this interview succeed in bettering our understanding of how individuals experienced this historic event? (the look, feel, sounds, smells and textures of different locations, the emotions felt, the intricacies and complexities of life)</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="succeedInBettering" value="Yes" id="yesSucceedInBettering" formControlName="succeedInBettering">
      <label class="form-check-label" for="yesSucceedInBettering">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="succeedInBettering" value="No" id="noSucceedInBettering" formControlName="succeedInBettering">
      <label class="form-check-label" for="noSucceedInBettering">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="scoreContent" class="required">Overall content score (1 being poor and 10 being excellent)</label>
    <input type="number" min="1" max="10" class="form-input" id="scoreContent" formControlName="scoreContent">
  </div>
  <div class="form-group">
    <label for="contentNotes" class="required">Notes and Comments</label>
    <textarea id="contentNotes" class="w-100 form-textarea" formControlName="contentNotes" (paste)="onPaste($event, 'contentNotes')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"   class="save-button" (click)="saveForm()">Save</button>
</div>
