<div class="form-title mt-3 mb-5">
    Copy-Editing and Curation
</div>
<p style="margin-left:54px">
  Raw, Unedited Summary:
  <br>
  {{ rawUneditedSummary }}
</p>
<div class="form-data" [formGroup]="copyEditingAndCurationForm">
  <div class="form-group">
    <label for="copyEditedSummaryDate">Summary Copy-Editing Date Assigned</label>
    <div class="input-group w-50">
      <input class="form-datepicker" placeholder="MM/DD/YYYY" ngbDatepicker #d="ngbDatepicker"
        formControlName="copyEditedSummaryDate" id="copyEditedSummaryDate">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar3" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
            <path fill-rule="evenodd"
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group">
      <label for="copyEditedSummary">Copy-Edited Summary</label>
      <textarea
        autosize
        id="copyEditedSummary"
        formControlName="copyEditedSummary"
        class="w-100 form-textarea"
        (paste)="onPaste($event, 'copyEditedSummary')">
      </textarea>
  </div>
  <div class="form-group">
    <label for="factCheckEmailSentDate">Fact Check Sent Date</label>
    <div class="input-group w-50">
      <input class="form-datepicker" placeholder="MM/DD/YYYY" ngbDatepicker #d1="ngbDatepicker"
        formControlName="factCheckEmailSentDate" id="factCheckEmailSentDate">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar3" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
            <path fill-rule="evenodd"
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="factCheckCompletedDate">Fact Check Completed Date</label>
    <div class="input-group w-50">
      <input class="form-datepicker" placeholder="MM/DD/YYYY" ngbDatepicker #d2="ngbDatepicker"
        formControlName="factCheckCompletedDate" id="factCheckCompletedDate">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar3" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
            <path fill-rule="evenodd"
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="curatedSummary">Curated Summary</label>
    <textarea id="curatedSummary" formControlName="curatedSummary" class="w-100 form-textarea" (paste)="onPaste($event, 'curatedSummary')"></textarea>
  </div>
  <div class="form-group">
    <label for="notes">Notes</label>
    <textarea id="notes" formControlName="notes" class="w-100 form-textarea" (paste)="onPaste($event, 'notes')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"   class="save-button" (click)="saveForm()">Save</button>
</div>
