<div class="form-title mt-3 mb-3">
    Birthplace Location
</div>
<div class="form-data" [formGroup]="birthplaceAddressForm">
    <div class="form-group">
        <label for="houseNumberBirthplace">House Number</label>
        <input type="text" class="form-input" id="houseNumberBirthplace" formControlName="houseNumberBirthplace">
    </div>
    <div class="form-group">
        <label for="streetBirthplace">Street</label>
        <input type="text" class="form-input" id="streetBirthplace"  formControlName="streetBirthplace">
    </div>
    <div class="form-group">
        <label for="cityBirthplace" class="required">City/Village</label>
        <input type="text" class="form-input" id="cityBirthplace" formControlName="cityBirthplace">
    </div>
    <div class="form-group">
        <label for="stateBirthplace" class="required">State/Province/Division</label>
        <input type="text" class="form-input" id="stateBirthplace"  formControlName="stateBirthplace">
    </div>
    <div class="form-group">
        <label for="districtBirthplace">District/Tehsil/Jilla</label>
        <input type="text" class="form-input" id="districtBirthplace" formControlName="districtBirthplace">
    </div>
    <div class="form-group">
        <label for="postalCodeBirthplace">Zip/Postal Code</label>
        <input type="text" class="form-input" id="postalCodeBirthplace" formControlName="postalCodeBirthplace">
    </div>
    <div class="form-group">
        <label for="countryBirthplace" class="required">Country</label>
        <input type="text" class="form-input" id="countryBirthplace"  formControlName="countryBirthplace">
    </div>
    <div class="form-group">
        <label for="villageBirthplace">For Village/Town: precise details for birthplace address</label>
        <input type="text" class="form-input" id="villageBirthplace"  formControlName="villageBirthplace">
    </div>
    <p style="margin-top:33px">
        For latitude and longitude coordinates, write the information according to this format: <b>##.####</b>. Do not input North (N) or South (S), East (E), or West (W) letters, or degree sign.
        <br><br>
        <span style="text-decoration:underline">Example:</span>
        <br>
        Berkeley, California, USA: <b>37.8717</b> (latitude) and <b>-122.2728</b> (longitude)
        <br>
        Lahore, Punjab, Pakistan: <b>31.5204</b> (latitude) and <b>74.3587</b> (longitude)
        <br>
        Perth, Western Australia, Australia: <b>-31.9505</b> (latitude) and <b>115.8605</b> (longitude)
      </p>
    <div class="form-group">
        <label for="placeOfBirth" class="required">Interviewee Place Of Birth Location (Village/City, State, Country)</label>
        <input type="text" class="form-input" id="placeOfBirth" formControlName="placeOfBirth">
    </div>
    <div class="form-group">
        <label for="latitudeBirthplace" class="required">Latitude of Birthplace</label>
        <input type="text" class="form-input" id="latitudeBirthplace"  formControlName="latitudeBirthplace">
    </div>
    <div class="form-group">
        <label for="longitudeBirthplace" class="required">Longitude of Birthplace</label>
        <input type="text" class="form-input" id="longitudeBirthplace"  formControlName="longitudeBirthplace">
    </div>
</div>
<div class="mt-5 text-center">
    <button  class="save-button" (click)="saveForm()">Save</button>
</div>
