<div class="form-title mt-3 mb-3">
    Interviewee Contact Information
</div>
<div class="form-data" [formGroup]="contactInformationForm">
    <div class="form-group">
        <label for="houseNumberCurrentResidence" class="required">House Number for current interviewee residence</label>
        <input type="text" class="form-input" id="houseNumberCurrentResidence" formControlName="houseNumberCurrentResidence">
    </div>
    <div class="form-group">
        <label for="streetCurrentResidence" class="required">Street of current interviewee residence</label>
        <input type="text" class="form-input" id="streetCurrentResidence"  formControlName="streetCurrentResidence">
    </div>
    <div class="form-group">
        <label for="cityCurrentResidence" class="required">City/Village of current interviewee residence</label>
        <input type="text" class="form-input" id="cityCurrentResidence" formControlName="cityCurrentResidence">
    </div>
    <div class="form-group">
        <label for="stateCurrentResidence" class="required">State/Province/Division of current interviewee residence</label>
        <input type="text" class="form-input" id="stateCurrentResidence"  formControlName="stateCurrentResidence">
    </div>
    <div class="form-group">
        <label for="districtCurrentResidence" class="required">District/Tehsil/Jilla of current interviewee residence</label>
        <input type="text" class="form-input" id="districtCurrentResidence" formControlName="districtCurrentResidence">
    </div>
    <div class="form-group">
        <label for="postalCodeCurrentResidence" class="required">Zip/Postal Code of current interviewee residence</label>
        <input type="text" class="form-input" id="postalCodeCurrentResidence" formControlName="postalCodeCurrentResidence">
    </div>
    <div class="form-group">
        <label for="countryCurrentResidence" class="required">Country of current interviewee residence</label>
        <input type="text" class="form-input" id="countryCurrentResidence"  formControlName="countryCurrentResidence">
    </div>
    <div class="form-group">
        <label for="village">For City/Village/Town: Precise details of current interviewee residence</label>
        <input type="text" class="form-input" id="village"  formControlName="villageDetails">
    </div>
    <p style="margin-top:33px">
        For latitude and longitude coordinates, write the information according to this format: <b>##.####</b>. Do not input North (N) or South (S), East (E), or West (W) letters, or degree sign.
        <br><br>
        <span style="text-decoration:underline">Example:</span>
        <br>
        Berkeley, California, USA: <b>37.8717</b> (latitude) and <b>-122.2728</b> (longitude)
        <br>
        Lahore, Punjab, Pakistan: <b>31.5204</b> (latitude) and <b>74.3587</b> (longitude)
        <br>
        Perth, Western Australia, Australia: <b>-31.9505</b> (latitude) and <b>115.8605</b> (longitude)
    </p>
    <div class="form-group">
        <label for="currentResidence" class="required">Interviewee Current Residence (City, State, Country)</label>
        <input type="text" class="form-input" id="currentResidence" formControlName="currentResidence">
    </div>
    <div class="form-group">
        <label for="latitudeCurrentResidence" class="required">Latitude of current residence</label>
        <input type="text" class="form-input" id="latitudeCurrentResidence"  formControlName="latitudeCurrentResidence">
    </div>
    <div class="form-group">
        <label for="longitudeCurrentResidence" class="required">Longitude of current residence</label>
        <input type="text" class="form-input" id="longitudeCurrentResidence"  formControlName="longitudeCurrentResidence">
    </div>
    <div class="form-group">
        <label for="phoneNumberInterviewee" class="required">Interviewee Phone Number</label>
        <input type="text" class="form-input" id="phoneNumberInterviewee"  formControlName="phoneNumberInterviewee">
    </div>
    <div class="form-group">
        <label for="emailInterviewee" class="required">Interviewee email address</label>
        <input type="text" class="form-input" id="emailInterviewee"  formControlName="emailInterviewee">
    </div>
    <div class="form-group">
        <label for="facebook">Interviewee Facebook link</label>
        <input type="text" class="form-input" id="facebook"  formControlName="facebook">
    </div>
    <div class="form-group">
        <label for="twitter">Interviewee Twitter handle</label>
        <input type="text" class="form-input" id="twitter"  formControlName="twitter">
    </div>
    <div class="form-group">
        <label for="instagram">Interviewee Instagram Handle</label>
        <input type="text" class="form-input" id="instagram"  formControlName="instagram">
    </div>
    <div class="form-group">
        <label for="linkedin">Interviewee LinkedIn profile</label>
        <input type="text" class="form-input" id="linkedin"  formControlName="linkedin">
    </div>
    <div class="form-group">
        <label for="tiktok">Interviewee TikTok profile</label>
        <input type="text" class="form-input" id="tiktok"  formControlName="tiktok">
    </div>
    <div class="form-group">
        <label for="contactInformationAdditionalNotes">Notes</label>
        <textarea id="contactInformationAdditionalNotes" class="w-100 form-textarea" formControlName="contactInformationAdditionalNotes" (paste)="onPaste($event, 'contactInformationAdditionalNotes')"></textarea>
    </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"   class="save-button" (click)="saveForm()">Save</button>
</div>
