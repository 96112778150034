<div class="form-title mt-3 mb-3">
  Responses & Reaction
</div>
<div class="form-data" [formGroup]="responseReactionForm">
  <div class="form-group">
    <label class="required">If there was a moment when the interviewee felt awkward or was unsure of what to say, was the interviewer encouraging and did they help to smoothly restart the flow of dialoge?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="feltAwkward" value="Yes" id="yesFeltAwkward" formControlName="feltAwkward">
      <label class="form-check-label" for="yesFeltAwkward">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="feltAwkward" value="No" id="noFeltAwkward" formControlName="feltAwkward">
      <label class="form-check-label" for="noFeltAwkward">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer allow for pauses when the interviewee was thinking or recalling details?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="allowPause" value="Yes" id="yesallowPause" formControlName="allowPause">
      <label class="form-check-label" for="yesAllowPause">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="allowPause" value="No" id="noAllowPause" formControlName="allowPause">
      <label class="form-check-label" for="noAllowPause">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">If the interviewee became emotional, did the interviewer respond well? Were they supportive as the interviewee recounted sensitive or uncomfortable memories?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="sensitiveColResponses" value="Yes" id="yesSensitive" formControlName="sensitiveColResponses">
      <label class="form-check-label" for="yesSensitive">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="sensitiveColResponses" value="No" id="noSensitive" formControlName="sensitiveColResponses">
      <label class="form-check-label" for="noSensitive">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">If the conversation became emotionally difficult, did the interviewer help the interviewee to focus on strengths?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="focusOnStrengths" value="Yes" id="yesFocusOnStrengths" formControlName="focusOnStrengths">
      <label class="form-check-label" for="yesFocusOnStrengths">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="focusOnStrengths" value="No" id="noFocusOnStrengths" formControlName="focusOnStrengths">
      <label class="form-check-label" for="noFocusOnStrengths">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">If the interviewee wantered too far off topic, did the interviewer gently and discreetly redirect the conversation?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="offTopic" value="Yes" id="yesOffTopic" formControlName="offTopic">
      <label class="form-check-label" for="yesOffTopic">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="offTopic" value="No" id="noOffTopic" formControlName="offTopic">
      <label class="form-check-label" for="noOffTopic">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="scoreResponses" class="required">Overall Filming Score (1 being poor and 10 being excellent)</label>
    <input type="number" min="1" max="10" class="form-input" id="scoreResponses" formControlName="scoreResponses">
  </div>
  <div class="form-group">
    <label for="notesResponses" class="required">Notes and Comments</label>
    <textarea id="notesResponses" class="w-100 form-textarea" formControlName="notesResponses" (paste)="onPaste($event, 'notesResponses')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'" class="save-button" (click)="saveForm()">Save</button>
</div>
