<div class="form-title mt-3 mb-3">
  StoryMap
</div>
<div class="form-data" [formGroup]="archivistStoryMapForm">
  <div class="form-group">
    <label for="storyMap" class="required">Added to StoryMap</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="storyMap" value="yes" id="yesStoryMap" formControlName="storyMap">
      <label class="form-check-label" for="yesStoryMap">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="storyMap" value="no" id="noStoryMap" formControlName="storyMap">
      <label class="form-check-label" for="noStoryMap">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="interview_number">Interview Number</label>
    <input id="interview_number" class="form-input" formControlName="interview_number">
  </div>
  <div class="form-group">
    <label for="interview_date">Interview Date</label>
    <input id="interview_date" class="form-input" formControlName="interview_date">
  </div>
  <div class="form-group">
      <label for="interviewer">Interviewer</label>
      <input id="interviewer" class="form-input" formControlName="interviewer">
  </div>
  <div class="form-group">
      <label for="camera_person">Camera Person</label>
      <input id="camera_person" class="form-input" formControlName="camera_person">
  </div>
  <div class="form-group">
      <label for="interview_location_city_state_province_country">Interview Location (City, State/Province, Country)</label>
      <input id="interview_location_city_state_province_country" class="form-input" formControlName="interview_location_city_state_province_country">
  </div>
  <div class="form-group">
      <label for="interview_location_latitude">Interview Location Latitude</label>
      <input id="interview_location_latitude" class="form-input" formControlName="interview_location_latitude">
  </div>
  <div class="form-group">
      <label for="interview_location_longitude">Interview Location Longitude</label>
      <input id="interview_location_longitude" class="form-input" formControlName="interview_location_longitude">
  </div>
  <div class="form-group">
    <label for="interviewee_date_of_birth">Interviewee Date Of Birth</label>
    <input id="interviewee_date_of_birth" class="form-input" formControlName="interviewee_date_of_birth">
  </div>
  <div class="form-group">
    <label for="age_in_1947">Age in 1947</label>
    <input id="age_in_1947" class="form-input" formControlName="age_in_1947">
  </div>
  <div class="form-group">
    <label for="migrated_from_village_city_state_country">migrated_from_village_city_state_country</label>
    <input type="text" class="form-input" id="migrated_from_village_city_state_country"  formControlName="migrated_from_village_city_state_country">
  </div>
  <div class="form-group">
    <label for="migrated_from_latitude_coordinates">migrated_from_latitude_coordinates</label>
    <input type="text" class="form-input" id="migrated_from_latitude_coordinates"  formControlName="migrated_from_latitude_coordinates">
  </div>
  <div class="form-group">
    <label for="latitude">latitude</label>
    <input type="text" class="form-input" id="latitude"  formControlName="latitude">
  </div>
  <div class="form-group">
      <label for="migrated_from_longitude_coordinates">migrated_from_longitude_coordinates</label>
      <input type="text" class="form-input" id="migrated_from_longitude_coordinates"  formControlName="migrated_from_longitude_coordinates">
  </div>
  <div class="form-group">
      <label for="longitude">longitude</label>
      <input type="text" class="form-input" id="longitude"  formControlName="longitude">
  </div>
  <div class="form-group">
    <label for="migrated_to_village_city_state_country" class="required">migrated_to_village_city_state_country</label>
    <input type="text" class="form-input" id="migrated_to_village_city_state_country"  formControlName="migrated_to_village_city_state_country">
  </div>
  <div class="form-group">
      <label for="migrated_to_latitude_coordinates" class="required">migrated_to_latitude_coordinates</label>
      <input type="text" class="form-input" id="migrated_to_latitude_coordinates"  formControlName="migrated_to_latitude_coordinates">
  </div>
  <div class="form-group">
      <label for="migrated_to_longitude_coordinates" class="required">migrated_to_longitude_coordinates</label>
      <input type="text" class="form-input" id="migrated_to_longitude_coordinates"  formControlName="migrated_to_longitude_coordinates">
  </div>
  <div class="form-group">
      <label for="current_residence_city_state_country" class="required">current_residence_city_state_country</label>
      <input type="text" class="form-input" id="current_residence_city_state_country"  formControlName="current_residence_city_state_country">
  </div>
  <div class="form-group">
      <label for="current_residence_latitude_coordinates" class="required">current_residence_latitude_coordinates</label>
      <input type="text" class="form-input" id="current_residence_latitude_coordinates"  formControlName="current_residence_latitude_coordinates">
  </div>
  <div class="form-group">
    <label for="current_residence_longitude_coordinates" class="required">current_residence_longitude_coordinates</label>
    <input type="text" class="form-input" id="current_residence_longitude_coordinates"  formControlName="current_residence_longitude_coordinates">
  </div>
  <div class="form-group">
    <label for="archivist" class="required">Archivist</label>
    <input type="text" class="form-input" id="archivist" formControlName="archivist">
  </div>
  <div class="form-group">
    <label for="sponsor">Sponsor</label>
    <input type="text" class="form-input" id="sponsor" formControlName="sponsor">
  </div>
  <div class="form-group">
    <label for="storySummary">Story Summary Curated</label>
    <textarea id="storySummary" class="w-100 form-textarea" formControlName="storySummary" (paste)="onPaste($event, 'storySummary')"></textarea>
  </div>
  <div class="form-group">
    <label for="videoLink" class="required">Video Link</label>
    <input type="text" class="form-input" id="videoLink" formControlName="videoLink">
  </div>
  <div class="form-group">
    <label for="interviewerPhotoLink1" class="required">Interviewer Photo Link 1</label>
    <input type="text" class="form-input" id="interviewerPhotoLink1" formControlName="interviewerPhotoLink1">
  </div>
  <div class="form-group">
    <label for="interviewerPhotoLink2" class="required">Interviewer Photo Link 2</label>
    <input type="text" class="form-input" id="interviewerPhotoLink2" formControlName="interviewerPhotoLink2">
  </div>
  <div class="form-group">
    <label for="interviewerPhotoLink3" class="required">Interviewer Photo Link 3</label>
    <input type="text" class="form-input" id="interviewerPhotoLink3" formControlName="interviewerPhotoLink3">
  </div>
  <div class="form-group">
    <label for="interviewerPhotoLink4" class="required">Interviewer Photo Link 4</label>
    <input type="text" class="form-input" id="interviewerPhotoLink4" formControlName="interviewerPhotoLink4">
  </div>
  <div class="form-group">
    <label for="snapFromId">Snap from ID</label>
    <input type="text" class="form-input" id="snapFromId" formControlName="snapFromId">
  </div>
  <div class="form-group">
    <label for="snapToId">Snap to ID</label>
    <input type="text" class="form-input" id="snapToId" formControlName="snapToId">
  </div>
  <div class="form-group">
    <label for="field32">Field 32</label>
    <input type="text" class="form-input" id="field32" formControlName="field32">
  </div>
  <div class="form-group">
    <label for="field33">Field 33</label>
    <input type="text" class="form-input" id="field33" formControlName="field33">
  </div>
  <div class="form-group">
    <label for="field34">Field 34</label>
    <input type="text" class="form-input" id="field34" formControlName="field34">
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"   class="save-button" (click)="saveForm()">Save</button>
</div>
