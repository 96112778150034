<div class="form-title mt-3 mb-5">
    Archivist
</div>
<div class="form-data" [formGroup]="archivistForm">
    <div class="form-select-container">
        <div class="form-group form-select-container">
            <label for="assignedSelect">Assigned To</label>
            <select id="assignedSelect" formControlName="assignedSelect" class="form-select">
                <option *ngFor="let option of assignedToOptions" [value]="option.value">{{ option.display }}</option>
            </select>
        </div>
        <div class="form-group form-select-container">
            <label for="prioritySelect">Priority</label>
            <select id="prioritySelect" formControlName="prioritySelect" class="form-select">
                <option *ngFor="let option of priorityOptions">{{ option }}</option>
            </select>
        </div>
        <div class="form-group form-select-container">
            <label for="statusSelect">Status</label>
            <select id="statusSelect" formControlName="statusSelect" class="form-select" (ngModelChange)="updateValidators()">
                <option *ngFor="let option of statusOptions">{{ option }}</option>
            </select>
        </div>
    </div>
    <div class="form-group">
        <label for="internalNotes">Internal Notes</label>
        <textarea id="internalNotes" formControlName="internalNotes" class="w-100 form-textarea" (paste)="onPaste($event, 'internalNotes')"></textarea>
    </div>
    <div class="form-group form-select-container" *ngIf="userType === 'admin'">
        <button class="save-button" style="width:190px;outline:none" (click)="delete()">Delete Interview</button>
    </div>
    <div class="form-group">
        <label class="required">Archiving Complete</label>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="archivingComplete" value="yes" id="yesArchivingComplete" formControlName="archivingComplete">
        <label class="form-check-label" for="yesArchivingComplete">
            Yes
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="archivingComplete" value="no" id="noArchivingComplete" formControlName="archivingComplete">
        <label class="form-check-label" for="noArchivingComplete">
            No
        </label>
        </div>
    </div>
    <div class="form-group">
        <label for="dateOfInterviewArchived" class="required">Date of Interview Archived</label>
        <div class="input-group w-50">
        <input class="form-datepicker" placeholder="MM/DD/YYYY" name="dp" ngbDatepicker #d="ngbDatepicker" formControlName="dateOfInterviewArchived">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                <path fill-rule="evenodd" d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
            </button>
        </div>
        </div>
    </div>
    <div class="form-group">
        <label for="archivistNotes">Archivist Notes</label>
        <textarea id="archivistNotes" formControlName="archivistNotes" class="w-100 form-textarea" (paste)="onPaste($event, 'archivistNotes')"></textarea>
    </div>
    <div class="form-group">
        <label for="auditAssignmentDate" class="required">Audit Assignment Date</label>
        <input type="text" class="form-input" id="auditAssignmentDate" formControlName="auditAssignmentDate">
    </div>
    <div class="form-group">
        <label for="auditAssignmentAdditionalDate" class="required">Audit Assignment Additional Date</label>
        <input type="text" class="form-input" id="auditAssignmentAdditionalDate"  formControlName="auditAssignmentAdditionalDate">
    </div>
    <div class="form-group">
        <label>POST Form</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="postForm" value="yes" id="yesPostForm" formControlName="postForm">
            <label class="form-check-label" for="yesPostForm">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="postForm" value="no" id="noPostForm" formControlName="postForm">
            <label class="form-check-label" for="noPostForm">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="postForm" value="none" id="nonePostForm" formControlName="postForm">
            <label class="form-check-label" for="nonePostForm">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label>Summary</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="summary" value="yes" id="yesSummary" formControlName="summary">
            <label class="form-check-label" for="yesSummary">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="summary" value="no" id="noSummary" formControlName="summary">
            <label class="form-check-label" for="noSummary">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="summary" value="none" id="noneSummary" formControlName="summary">
            <label class="form-check-label" for="noneSummary">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label>Summary Added to Curation doc?</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="summaryAdded" value="yes" id="yesSummaryAdded" formControlName="summaryAdded">
            <label class="form-check-label" for="yesSummaryAdded">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="summaryAdded" value="no" id="noSummaryAdded" formControlName="summaryAdded">
            <label class="form-check-label" for="noSummaryAdded">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="summaryAdded" value="none" id="noneSummaryAdded" formControlName="summaryAdded">
            <label class="form-check-label" for="noneSummaryAdded">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label>RELEASE Form</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="releaseForm" value="yes" id="yesReleaseForm" formControlName="releaseForm">
            <label class="form-check-label" for="yesReleaseForm">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="releaseForm" value="no" id="noReleaseForm" formControlName="releaseForm">
            <label class="form-check-label" for="noReleaseForm">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="releaseForm" value="none" id="noneReleaseForm" formControlName="releaseForm">
            <label class="form-check-label" for="noneReleaseForm">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label for="infoMissing" class="required">Info Missing - Post Interview Form</label>
        <textarea id="infoMissing" class="w-100 form-textarea" formControlName="infoMissing" (paste)="onPaste($event, 'infoMissing')"></textarea>
    </div>
    <div class="form-group">
        <label class="required">Contact Checklist?</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="contactChecklist" value="yes" id="yesContactChecklist" formControlName="contactChecklist">
            <label class="form-check-label" for="yesContactChecklist">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="contactChecklist" value="no" id="noContactChecklist" formControlName="contactChecklist">
            <label class="form-check-label" for="noContactChecklist">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="contactChecklist" value="none" id="noneContactChecklist" formControlName="contactChecklist">
            <label class="form-check-label" for="noneContactChecklist">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label class="required">Email Checklist?</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="emailChecklist" value="yes" id="yesEmailChecklist" formControlName="emailChecklist">
            <label class="form-check-label" for="yesEmailChecklist">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="emailChecklist" value="no" id="noEmailChecklist" formControlName="emailChecklist">
            <label class="form-check-label" for="noEmailChecklist">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="emailChecklist" value="none" id="noneEmailChecklist" formControlName="emailChecklist">
            <label class="form-check-label" for="noneEmailChecklist">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label for="bRollReceived">B-Roll Received</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="bRollReceived" value="yes" id="yesBRollReceived" formControlName="bRollReceived">
            <label class="form-check-label" for="yesBRollReceived">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="bRollReceived" value="no" id="noBRollReceived" formControlName="bRollReceived">
            <label class="form-check-label" for="noBRollReceived">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="bRollReceived" value="none" id="noneBRollReceived" formControlName="bRollReceived">
            <label class="form-check-label" for="noneBRollReceived">
                None
            </label>
        </div>
      </div>
    <div class="form-group">
        <label class="required">Video/Audio Received</label>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="videoAudioReceived" value="yes" id="yesVideoAudioReceived" formControlName="videoAudioReceived">
            <label class="form-check-label" for="yesVideoAudioReceived">
                Yes
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="videoAudioReceived" value="no" id="noVideoAudioReceived" formControlName="videoAudioReceived">
            <label class="form-check-label" for="noVideoAudioReceived">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="videoAudioReceived" value="none" id="noneVideoAudioReceived" formControlName="videoAudioReceived">
            <label class="form-check-label" for="noneVideoAudioReceived">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label for="lengthOfVideo" class="required">Length of Video (hrs:mins:secs)</label>
        <input type="text" class="form-input" id="lengthOfVideo" formControlName="lengthOfVideo">
    </div>
    <div class="form-group">
        <label class="required">Docs added to Supporting Materials</label>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="docsAddedToMaterial" value="yes" id="yesDocsAddedToMaterial" formControlName="docsAddedToMaterial">
        <label class="form-check-label" for="yesDocsAddedToMaterial">
            Yes
        </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="docsAddedToMaterial" value="no" id="noDocsAddedToMaterial" formControlName="docsAddedToMaterial">
            <label class="form-check-label" for="noDocsAddedToMaterial">
                No
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="docsAddedToMaterial" value="none" id="noneDocsAddedToMaterial" formControlName="docsAddedToMaterial">
            <label class="form-check-label" for="noneDocsAddedToMaterial">
                None
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="docsAddedToMaterial" value="post only" id="postOnlyDocsAddedToMaterial" formControlName="docsAddedToMaterial">
            <label class="form-check-label" for="postOnlyDocsAddedToMaterial">
                Post Only
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="docsAddedToMaterial" value="release only" id="releaseOnlyDocsAddedToMaterial" formControlName="docsAddedToMaterial">
            <label class="form-check-label" for="releaseOnlyDocsAddedToMaterial">
                Release Only
            </label>
        </div>
    </div>
    <div class="form-group">
        <label class="required">Files Copied to Downloaded folder on AWS</label>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="filesCopiedToAWS" value="yes" id="yesFilesCopiedToAWS" formControlName="filesCopiedToAWS">
        <label class="form-check-label" for="yesFilesCopiedToAWS">
            Yes
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="filesCopiedToAWS" value="no" id="noFilesCopiedToAWS" formControlName="filesCopiedToAWS">
        <label class="form-check-label" for="noFilesCopiedToAWS">
            No
        </label>
        </div>
    </div>
    <div class="form-group">
        <label class="required">Docs added to Glacier</label>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="docsAddedToGlacier" value="yes" id="yesDocsAddedToGlacier" formControlName="docsAddedToGlacier">
        <label class="form-check-label" for="yesDocsAddedToGlacier">
            Yes
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="docsAddedToGlacier" value="no" id="noDocsAddedToGlacier" formControlName="docsAddedToGlacier">
        <label class="form-check-label" for="noDocsAddedToGlacier">
            No
        </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="docsAddedToGlacier" value="none" id="noneDocsAddedToGlacier" formControlName="docsAddedToGlacier">
            <label class="form-check-label" for="noneDocsAddedToGlacier">
                None
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="docsAddedToGlacier" value="post only" id="postOnlyDocsAddedToGlacier" formControlName="docsAddedToGlacier">
            <label class="form-check-label" for="postOnlyDocsAddedToGlacier">
                Post Only
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="docsAddedToGlacier" value="release only" id="releaseOnlyDocsAddedToGlacier" formControlName="docsAddedToGlacier">
            <label class="form-check-label" for="releaseOnlyDocsAddedToGlacier">
                Release Only
            </label>
        </div>
    </div>
    <div class="form-group">
        <label class="required">Photos added to Glacier</label>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="photosAddedToGlacier" value="yes" id="yesPhotosAddedToGlacier" formControlName="photosAddedToGlacier">
        <label class="form-check-label" for="yesPhotosAddedToGlacier">
            Yes
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="photosAddedToGlacier" value="no" id="noPhotosAddedToGlacier" formControlName="photosAddedToGlacier">
        <label class="form-check-label" for="noPhotosAddedToGlacier">
            No
        </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="photosAddedToGlacier" value="none" id="nonePhotosAddedToGlacier" formControlName="photosAddedToGlacier">
            <label class="form-check-label" for="nonePhotosAddedToGlacier">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label class="required">Videos added to Glacier</label>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="videosAddedToGlacier" value="yes" id="yesVideosAddedToGlacier" formControlName="videosAddedToGlacier">
        <label class="form-check-label" for="yesVideosAddedToGlacier">
            Yes
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="videosAddedToGlacier" value="no" id="noVideosAddedToGlacier" formControlName="videosAddedToGlacier">
        <label class="form-check-label" for="noVideosAddedToGlacier">
            No
        </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="videosAddedToGlacier" value="none" id="noneVideosAddedToGlacier" formControlName="videosAddedToGlacier">
            <label class="form-check-label" for="noneVideosAddedToGlacier">
                None
            </label>
        </div>
    </div>
    <div class="form-group">
        <label for="backupDrive" class="required">Backup Drive</label>
        <input type="text" class="form-input" id="backupDrive" formControlName="backupDrive">
    </div>
    <div class="form-group">
        <label class="required">Doc & Video Confirm Email Sent</label>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="confirmEmailSent" value="yes" id="yesConfirmEmailSent" formControlName="confirmEmailSent">
        <label class="form-check-label" for="yesConfirmEmailSent">
            Yes
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="confirmEmailSent" value="no" id="noConfirmEmailSent" formControlName="confirmEmailSent">
        <label class="form-check-label" for="noConfirmEmailSent">
            No
        </label>
        </div>
    </div>
    <div class="form-group" *ngIf="userType === 'admin'">
        <label for="camera" class="required">Camera</label>
        <input type="text" class="form-input" id="camera" formControlName="camera">
    </div>
    <div class="form-group" *ngIf="userType === 'admin'">
        <label for="tape" class="required">Tape</label>
        <input type="text" class="form-input" id="tape" formControlName="tape">
    </div>
    <div class="form-group" *ngIf="userType === 'admin'">
        <label for="form" class="required">Form</label>
        <input type="text" class="form-input" id="form" formControlName="form">
    </div>
    <div class="form-group" *ngIf="userType === 'admin'">
        <label for="formFormat" class="required">Form Format</label>
        <input type="text" class="form-input" id="formFormat" formControlName="formFormat">
    </div>
    <div class="form-group">
        <label for="otherNotes" class="required">Other Notes, Requests</label>
        <textarea id="otherNotes" class="w-100 form-textarea" formControlName="otherNotes" (paste)="onPaste($event, 'otherNotes')"></textarea>
    </div>
    <div class="form-group">
        <label for="originalFormat">Original video/audio format</label>
        <input type="text" class="form-input" id="originalFormat" formControlName="originalFormat">
      </div>
      <div class="form-group">
        <label for="originalBRollFormat">Original B-roll format</label>
        <input type="text" class="form-input" id="originalBRollFormat" formControlName="originalBRollFormat">
      </div>
      <div class="form-group">
        <label for="digitalSize">Digital storage size</label>
        <input type="text" class="form-input" id="digitalSize" formControlName="digitalSize">
      </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"   class="save-button" (click)="saveForm()">Save</button>
</div>
