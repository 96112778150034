import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-interview-review-other',
  templateUrl: './interview-review-other.component.html',
  styleUrls: ['./interview-review-other.component.scss']
})
export class InterviewReviewOtherComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public otherForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.otherForm = this.fb.group({
      interviewReviewOtherID: [null],
      interviewID: [null],
      offensiveComments: [null],
      politicalBias: [null],
      religiousBias: [null],
      socioEconomicBias: [null],
      genderBias: [null],
      ethnicBias: [null],
      conformsToNorms: [null],
      excessiveHearsay: [null],
      hatefulAccusations: [null],
      incriminatoryDescriptions: [null],
      swearing: [null],
      showingHostileBehavior: [null],
      expressingMaliciousIntent: [null],
      warStrategies: [null],
      politicalSecrets: [null],
      religiousConversionMention: [null],
      concealedIdentities: [null],
      lighting: [null],
      framing: [null],
      sound: [null],
      otherComments: [null],
      intendedAudience: [null],
      groupsMentionedContact: [null],
      groupsMentionedNames: [null],
      subjects: [null],
      topicalDateRange: [null],
      communityRepresented: [null],
      reviewed: [null ],
      entryReviewNotes: [null ],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewReviewOther(this.interviewID).subscribe(information => {
        if (information) {
          this.otherForm = this.fb.group({
            offensiveComments: [information.offensiveComments],
            politicalBias: [information.politicalBias],
            religiousBias: [information.religiousBias],
            socioEconomicBias: [information.socioEconomicBias],
            genderBias: [information.genderBias],
            ethnicBias: [information.ethnicBias],
            conformsToNorms: [information.conformsToNorms],
            excessiveHearsay: [information.excessiveHearsay],
            hatefulAccusations: [information.hatefulAccusations],
            incriminatoryDescriptions: [information.incriminatoryDescriptions],
            swearing: [information.swearing],
            showingHostileBehavior: [information.showingHostileBehavior],
            expressingMaliciousIntent: [information.expressingMaliciousIntent],
            warStrategies: [information.warStrategies],
            politicalSecrets: [information.politicalSecrets],
            religiousConversionMention: [information.religiousConversionMention],
            concealedIdentities: [information.concealedIdentities],
            lighting: [information.lighting],
            framing: [information.framing],
            sound: [information.sound],
            otherComments: [information.otherComments],
            intendedAudience: [information.intendedAudience],
            groupsMentionedContact: [information.groupsMentionedContact],
            groupsMentionedNames: [information.groupsMentionedNames],
            subjects: [information.subjects],
            topicalDateRange: [information.topicalDateRange],
            communityRepresented: [information.communityRepresented],
            reviewed: [information.reviewed ],
            entryReviewNotes: [information.entryReviewNotes ],
          });
        }
      });
    }
  }

  saveForm() {
    const offensiveComments = this.otherForm.controls['offensiveComments'].value;
    const politicalBias = this.otherForm.controls['politicalBias'].value;
    const religiousBias = this.otherForm.controls['religiousBias'].value;
    const socioEconomicBias = this.otherForm.controls['socioEconomicBias'].value;
    const genderBias = this.otherForm.controls['genderBias'].value;
    const ethnicBias = this.otherForm.controls['ethnicBias'].value;
    const conformsToNorms = this.otherForm.controls['conformsToNorms'].value;
    const excessiveHearsay = this.otherForm.controls['excessiveHearsay'].value;
    const hatefulAccusations = this.otherForm.controls['hatefulAccusations'].value;
    const incriminatoryDescriptions = this.otherForm.controls['incriminatoryDescriptions'].value;
    const swearing = this.otherForm.controls['swearing'].value;
    const showingHostileBehavior = this.otherForm.controls['showingHostileBehavior'].value;
    const expressingMaliciousIntent = this.otherForm.controls['expressingMaliciousIntent'].value;
    const warStrategies = this.otherForm.controls['warStrategies'].value;
    const politicalSecrets = this.otherForm.controls['politicalSecrets'].value;
    const religiousConversionMention = this.otherForm.controls['religiousConversionMention'].value;
    const concealedIdentities = this.otherForm.controls['concealedIdentities'].value;
    const lighting = this.otherForm.controls['lighting'].value;
    const framing = this.otherForm.controls['framing'].value;
    const sound = this.otherForm.controls['sound'].value;
    const otherComments = this.otherForm.controls['otherComments'].value;
    const intendedAudience = this.otherForm.controls['intendedAudience'].value;
    const groupsMentionedContact = this.otherForm.controls['groupsMentionedContact'].value;
    const groupsMentionedNames = this.otherForm.controls['groupsMentionedNames'].value;
    const subjects = this.otherForm.controls['subjects'].value;
    const topicalDateRange = this.otherForm.controls['topicalDateRange'].value;
    const communityRepresented = this.otherForm.controls['communityRepresented'].value;
    const reviewed = this.otherForm.controls['reviewed'].value;
    const entryReviewNotes = this.otherForm.controls['entryReviewNotes'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      offensiveComments,
      politicalBias,
      religiousBias,
      socioEconomicBias,
      genderBias,
      ethnicBias,
      conformsToNorms,
      excessiveHearsay,
      hatefulAccusations,
      incriminatoryDescriptions,
      swearing,
      showingHostileBehavior,
      expressingMaliciousIntent,
      warStrategies,
      politicalSecrets,
      religiousConversionMention,
      concealedIdentities,
      lighting,
      framing,
      sound,
      otherComments,
      intendedAudience,
      groupsMentionedContact,
      groupsMentionedNames,
      subjects,
      topicalDateRange,
      communityRepresented,
      reviewed,
      entryReviewNotes
    };
    this.backendService.saveInterviewReviewOther(data)
      .subscribe(() => this.toastr.success('Red Flags', 'Data saved successfully'))
    this.formSaved.emit();
  }

  get userType() {
    return this.authService.getUserType();
  }
  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
