import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-post-archiving-sponsor-information',
  templateUrl: './post-archiving-sponsor-information.component.html',
  styleUrls: ['./post-archiving-sponsor-information.component.scss']
})
export class PostArchivingSponsorInformationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public sponsorInformation: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.sponsorInformation = this.fb.group({
      sponsoredBy: [null ],
      sponsorFacebookURL: [null],
      sponsorTwitterHandle: [null],
      sponsorInstagramHandle: [null],
      sponsorLinkedInProfile: [null],
      sponsorTikTokProfile: [null],
    });
  }


  get userType() {
    return this.authService.getUserType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getPostArchivingSponsorInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.sponsorInformation = this.fb.group({
            sponsoredBy: [information.sponsoredBy ],
            sponsorFacebookURL: [information.facebookURL ],
            sponsorTwitterHandle: [information.sponsorTwitterHandle ],
            sponsorInstagramHandle: [information.sponsorInstagramHandle ],
            sponsorLinkedInProfile: [information.sponsorLinkedInProfile ],
            sponsorTikTokProfile: [information.sponsorTikTokProfile ],
          });
        }
      });
    }
  }

  saveForm() {
    const sponsoredBy = this.sponsorInformation.controls['sponsoredBy'].value;
    const sponsorFacebookURL = this.sponsorInformation.controls['sponsorFacebookURL'].value;
    const sponsorTwitterHandle = this.sponsorInformation.controls['sponsorTwitterHandle'].value;
    const sponsorInstagramHandle = this.sponsorInformation.controls['sponsorInstagramHandle'].value;
    const sponsorLinkedInProfile = this.sponsorInformation.controls['sponsorLinkedInProfile'].value;
    const sponsorTikTokProfile = this.sponsorInformation.controls['sponsorTikTokProfile'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      sponsoredBy,
      sponsorFacebookURL,
      sponsorTwitterHandle,
      sponsorInstagramHandle,
      sponsorLinkedInProfile,
      sponsorTikTokProfile
    };
    this.backendService.savePostArchivingSponsorInformation(data)
      .subscribe(() => this.toastr.success('Sponsor Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

}
