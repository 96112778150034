import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-parental-information',
  templateUrl: './parental-information.component.html',
  styleUrls: ['./parental-information.component.scss']
})
export class ParentalInformationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public parentalInformationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }


  get userType() {
    return this.authService.getUserType();
  }

  ngOnInit(): void {
    this.parentalInformationForm = this.fb.group({
      occupation1947: [null ],
      career: [null ],
      occupationToday: [null ],
      fatherName: [null ],
      motherName: [null ]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getParentalInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.parentalInformationForm = this.fb.group({
            occupation1947: [ information.occupation1947 ],
            career: [ information.career ],
            occupationToday: [ information.occupationToday ],
            fatherName: [ information.fatherName ],
            motherName: [ information.motherName ]
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.parentalInformationForm.controls['occupation1947'].setValidators(Validators.required);
      this.parentalInformationForm.controls['occupation1947'].updateValueAndValidity();
      this.parentalInformationForm.controls['career'].setValidators(Validators.required);
      this.parentalInformationForm.controls['career'].updateValueAndValidity();
      this.parentalInformationForm.controls['occupationToday'].setValidators(Validators.required);
      this.parentalInformationForm.controls['occupationToday'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.parentalInformationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const occupation1947 = this.parentalInformationForm.controls['occupation1947'].value;
    const career = this.parentalInformationForm.controls['career'].value;
    const occupationToday = this.parentalInformationForm.controls['occupationToday'].value;
    const fatherName = this.parentalInformationForm.controls['fatherName'].value;
    const motherName = this.parentalInformationForm.controls['motherName'].value;
    const interviewID = this.interviewID;
    let data = { interviewID, occupation1947, career, occupationToday, fatherName, motherName };
    this.backendService.saveParentalInformation(data)
      .subscribe(() => this.toastr.success('Interviewee Occupation and Parental Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

}
