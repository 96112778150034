import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-archivist-storymap',
  templateUrl: './archivist-storymap.component.html',
  styleUrls: ['./archivist-storymap.component.scss']
})
export class ArchivistStoryMapComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public archivistStoryMapForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.archivistStoryMapForm = this.fb.group({
      storyMap: [null],
      interview_number: [null],
      interview_date: [null],
      interviewer: [null],
      camera_person: [null],
      interview_location_city_state_province_country: [null],
      interview_location_latitude: [null],
      interview_location_longitude: [null],
      interviewee_date_of_birth: [null],
      age_in_1947: [null],
      migrated_from_village_city_state_country: [null],
      migrated_from_latitude_coordinates: [null],
      latitude: [null],
      migrated_from_longitude_coordinates: [null],
      longitude: [null],
      migrated_to_village_city_state_country: [null],
      migrated_to_latitude_coordinates: [null],
      migrated_to_longitude_coordinates: [null],
      current_residence_city_state_country: [null],
      current_residence_latitude_coordinates: [null],
      current_residence_longitude_coordinates: [null],
      archivist: [null],
      sponsor: [null],
      storySummary: [null],
      videoLink: [null],
      interviewerPhotoLink1: [null],
      interviewerPhotoLink2: [null],
      interviewerPhotoLink3: [null],
      interviewerPhotoLink4: [null],
      snapFromId: [null],
      snapToId: [null],
      field32: [null],
      field33: [null],
      field34: [null],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getArchivistStoryMap(this.interviewID).subscribe(information => {
        if (information) {
          this.archivistStoryMapForm = this.fb.group({
            storyMap: [information.storyMap],
            interview_number: [information.interview_number],
            interview_date: [information.interview_date],
            interviewer: [information.interviewer],
            camera_person: [information.camera_person],
            interview_location_city_state_province_country: [information.interview_location_city_state_province_country],
            interview_location_latitude: [information.interview_location_latitude],
            interview_location_longitude: [information.interview_location_longitude],
            interviewee_date_of_birth: [information.interviewee_date_of_birth],
            age_in_1947: [information.age_in_1947],
            migrated_from_village_city_state_country: [information.migrated_from_village_city_state_country],
            migrated_from_latitude_coordinates: [information.migrated_from_latitude_coordinates],
            latitude: [information.latitude],
            migrated_from_longitude_coordinates: [information.migrated_from_longitude_coordinates],
            longitude: [information.longitude],
            migrated_to_village_city_state_country: [information.migrated_to_village_city_state_country],
            migrated_to_latitude_coordinates: [information.migrated_to_latitude_coordinates],
            migrated_to_longitude_coordinates: [information.migrated_to_longitude_coordinates],
            current_residence_city_state_country: [information.current_residence_city_state_country],
            current_residence_latitude_coordinates: [information.current_residence_latitude_coordinates],
            current_residence_longitude_coordinates: [information.current_residence_longitude_coordinates],
            archivist: [information.archivist],
            sponsor: [information.sponsor],
            storySummary: [information.storySummary],
            videoLink: [information.videoLink],
            interviewerPhotoLink1: [information.interviewerPhotoLink1],
            interviewerPhotoLink2: [information.interviewerPhotoLink2],
            interviewerPhotoLink3: [information.interviewerPhotoLink3],
            interviewerPhotoLink4: [information.interviewerPhotoLink4],
            snapFromId: [information.snapFromId],
            snapToId: [information.snapToId],
            field32: [information.field32],
            field33: [information.field33],
            field34: [information.field34]
          });
        }
      });
    }
  }

  get userType() {
    return this.authService.getUserType();
  }

  saveForm() {
    const interview_number = this.archivistStoryMapForm.controls['interview_number'].value;
    const storyMap = this.archivistStoryMapForm.controls['storyMap'].value;
    const interview_date = this.archivistStoryMapForm.controls['interview_date'].value;
    const interviewer = this.archivistStoryMapForm.controls['interviewer'].value;
    const camera_person = this.archivistStoryMapForm.controls['camera_person'].value;
    const interview_location_city_state_province_country = this.archivistStoryMapForm.controls['interview_location_city_state_province_country'].value;
    const interview_location_latitude = this.archivistStoryMapForm.controls['interview_location_latitude'].value;
    const interview_location_longitude = this.archivistStoryMapForm.controls['interview_location_longitude'].value;
    const interviewee_date_of_birth = this.archivistStoryMapForm.controls['interviewee_date_of_birth'].value;
    const age_in_1947 = this.archivistStoryMapForm.controls['age_in_1947'].value;
    const migrated_from_village_city_state_country = this.archivistStoryMapForm.controls['migrated_from_village_city_state_country'].value;
    const migrated_from_latitude_coordinates = this.archivistStoryMapForm.controls['migrated_from_latitude_coordinates'].value;
    const latitude = this.archivistStoryMapForm.controls['latitude'].value;
    const migrated_from_longitude_coordinates = this.archivistStoryMapForm.controls['migrated_from_longitude_coordinates'].value;
    const longitude = this.archivistStoryMapForm.controls['longitude'].value;
    const migrated_to_village_city_state_country = this.archivistStoryMapForm.controls['migrated_to_village_city_state_country'].value;
    const migrated_to_latitude_coordinates = this.archivistStoryMapForm.controls['migrated_to_latitude_coordinates'].value;
    const migrated_to_longitude_coordinates = this.archivistStoryMapForm.controls['migrated_to_longitude_coordinates'].value;
    const current_residence_city_state_country = this.archivistStoryMapForm.controls['current_residence_city_state_country'].value;
    const current_residence_latitude_coordinates = this.archivistStoryMapForm.controls['current_residence_latitude_coordinates'].value;
    const current_residence_longitude_coordinates = this.archivistStoryMapForm.controls['current_residence_longitude_coordinates'].value;
    const archivist = this.archivistStoryMapForm.controls['archivist'].value;
    const sponsor = this.archivistStoryMapForm.controls['sponsor'].value;
    const storySummary = this.archivistStoryMapForm.controls['storySummary'].value;
    const videoLink = this.archivistStoryMapForm.controls['videoLink'].value;
    const interviewerPhotoLink1 = this.archivistStoryMapForm.controls['interviewerPhotoLink1'].value;
    const interviewerPhotoLink2 = this.archivistStoryMapForm.controls['interviewerPhotoLink2'].value;
    const interviewerPhotoLink3 = this.archivistStoryMapForm.controls['interviewerPhotoLink3'].value;
    const interviewerPhotoLink4 = this.archivistStoryMapForm.controls['interviewerPhotoLink4'].value;
    const snapFromId = this.archivistStoryMapForm.controls['snapFromId'].value;
    const snapToId = this.archivistStoryMapForm.controls['snapToId'].value;
    const field32 = this.archivistStoryMapForm.controls['field32'].value;
    const field33 = this.archivistStoryMapForm.controls['field33'].value;
    const field34 = this.archivistStoryMapForm.controls['field34'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      interview_number,
      storyMap,
      interview_date,
      interviewer,
      camera_person,
      interview_location_city_state_province_country,
      interview_location_latitude,
      interview_location_longitude,
      interviewee_date_of_birth,
      age_in_1947,
      migrated_from_village_city_state_country,
      migrated_from_latitude_coordinates,
      latitude,
      migrated_from_longitude_coordinates,
      longitude,
      migrated_to_village_city_state_country,
      migrated_to_latitude_coordinates,
      migrated_to_longitude_coordinates,
      current_residence_city_state_country,
      current_residence_latitude_coordinates,
      current_residence_longitude_coordinates,
      archivist,
      sponsor,
      storySummary,
      videoLink,
      interviewerPhotoLink1,
      interviewerPhotoLink2,
      interviewerPhotoLink3,
      interviewerPhotoLink4,
      snapFromId,
      snapToId,
      field32,
      field33,
      field34
    };
    this.backendService.saveArchivistStoryMap(data)
      .subscribe(() => this.toastr.success('Archivist StoryMap', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
