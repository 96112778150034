<div class="form-title mt-3 mb-3">
    Interviewer Information
</div>
<div class="form-data" [formGroup]="interviewerForm">
    <div class="form-group">
        <label for="interviewerTitle">Interviewer title</label>
        <input type="text" class="form-input" id="interviewerTitle" formControlName="interviewerTitle">
    </div>
    <div class="form-group">
        <label for="interviewerFirstName" class="required">Interviewer first name</label>
        <input type="text" class="form-input" id="interviewerFirstName" formControlName="interviewerFirstName">
    </div>
    <div class="form-group">
        <label for="interviewerLastName" class="required">Interviewer last name</label>
        <input type="text" class="form-input" id="interviewerLastName" formControlName="interviewerLastName">
    </div>
    <div class="form-group">
        <label for="interviewerSuffix">Interviewer suffix</label>
        <input type="text" class="form-input" id="interviewerSuffix" formControlName="interviewerSuffix">
    </div>
    <div class="form-group">
        <label for="interviewer" class="required">Interviewer full name</label>
        <input type="text" class="form-input" id="interviewer" formControlName="interviewer">
    </div>
    <div class="form-group">
        <label for="interviewerReligion" class="required">Interviewer religion</label>
        <input type="text" class="form-input" id="interviewerReligion" formControlName="interviewerReligion">
    </div>
    <div class="form-group">
        <label for="interviewerDateOfBirth" class="required">Date of birth of the interviewer</label>
        <input type="text" class="form-input" id="interviewerDateOfBirth" formControlName="interviewerDateOfBirth">
    </div>
    <div class="form-group" *ngIf="userType !== 'interviewer'">
        <label for="numberOfInterviewsCompleted">Number of Interviews Completed by the Interviewer</label>
        <input type="text" class="form-input" id="numberOfInterviewsCompleted" formControlName="numberOfInterviewsCompleted">
    </div>
    <div class="form-group">
        <label for="interviewerLocation">Interviewer location (village/city, state, country)</label>
        <input type="text" class="form-input" id="interviewerLocation" formControlName="interviewerLocation">
    </div>
    <div class="form-group">
        <label for="interviewerOccupation" class="required">Interviewer occupation</label>
        <input type="text" class="form-input" id="interviewerOccupation" formControlName="interviewerOccupation">
    </div>
    <div class="form-title mb-3" style="margin:40px 0">
        Camera Person Information
    </div>
    <div class="form-group">
        <label for="cameraTitle">Camera person title</label>
        <input type="text" class="form-input" id="cameraTitle" formControlName="cameraTitle">
    </div>
    <div class="form-group">
        <label for="cameraFirstName">Camera person first name</label>
        <input type="text" class="form-input" id="cameraFirstName" formControlName="cameraFirstName">
    </div>
    <div class="form-group">
        <label for="cameraLastName">Camera person last name</label>
        <input type="text" class="form-input" id="cameraLastName" formControlName="cameraLastName">
    </div>
    <div class="form-group">
        <label for="cameraSuffix">Camera person suffix</label>
        <input type="text" class="form-input" id="cameraSuffix" formControlName="cameraSuffix">
    </div>
    <div class="form-group">
        <label for="cameraPerson">Camera person full name</label>
        <input type="text" class="form-input" id="cameraPerson" formControlName="cameraPerson">
    </div>
    <div class="form-group" *ngIf="userType !== 'interviewer'">
        <label for="interviewsCompleted">Number of interviews completed</label>
        <input type="text" class="form-input" id="interviewsCompleted" formControlName="interviewsCompleted">
    </div>
    <div class="form-group" *ngIf="userType !== 'interviewer'">
      <label for="interviewerDesignation">Interviewer Designation</label>
      <input type="text" class="form-input" id="designation" formControlName="designation">
    </div>
    <div class="form-group">
        <label for="background" class="required">Interviewer background/Personal reflection</label>
        <textarea id="background" class="w-100 form-textarea" formControlName="background" (paste)="onPaste($event, 'background')"></textarea>
    </div>
    <div class="form-group">
        <label for="interviewerNativeLanguage" class="required">Native language of interviewer</label>
        <input type="text" class="form-input" id="interviewerNativeLanguage"  formControlName="interviewerNativeLanguage">
    </div>
    <div class="form-group">
        <label for="interviewerRegionalLanguage">Regional dialect of interviewer</label>
        <input type="text" class="form-input" id="interviewerRegionalLanguage"  formControlName="interviewerRegionalLanguage">
    </div>
    <div class="form-group">
        <label for="connection" class="required">Interviewer / Interviewee connection</label>
        <textarea id="connection" class="w-100 form-textarea" formControlName="connection" (paste)="onPaste($event, 'connection')"></textarea>
    </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"  class="save-button" (click)="saveForm()">Save</button>
</div>
