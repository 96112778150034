<div class="form-title mt-3 mb-3">
    Interview File Format
</div>
<div class="form-data" [formGroup]="submissionForm">
  <div class="form-group">
    <label for="interviewFormat">Interview format</label>
    <input type="text" class="form-input" id="interviewFormat" formControlName="interviewFormat">
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"  class="save-button" (click)="saveForm()">Save</button>
</div>
