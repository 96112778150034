<div class="form-title mt-3 mb-3">
    Migration Details
</div>
<div class="form-data" [formGroup]="migrationDetailsForm">
    <div class="form-group">
        <label for="assetsLost" class="required">Assets lost during migration</label>
        <input type="text" class="form-input" id="assetsLost" formControlName="assetsLost">
    </div>
    <div class="form-group">
        <label for="assetsAlloted" class="required">Assets allotted by new government</label>
        <input type="text" class="form-input" id="assetsAlloted" formControlName="assetsAlloted">
    </div>
    <div class="form-group">
        <label for="pointOfEntry" class="required">Point of entry into new country</label>
        <input type="text" class="form-input" id="pointOfEntry"  formControlName="pointOfEntry">
    </div>
    <div class="form-group">
        <label for="routeTraveled" class="required">Route traveled to reach new country</label>
        <input type="text" class="form-input" id="routeTraveled" formControlName="routeTraveled">
    </div>
    <div class="form-group">
        <label for="additionMaterials" class="required">Additional materials to share</label>
        <input type="text" class="form-input" id="additionMaterials" formControlName="additionMaterials">
    </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'" class="save-button" (click)="saveForm()">Save</button>
</div>
