import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-interview-review-response-reaction',
  templateUrl: './interview-review-response-reaction.component.html',
  styleUrls: ['./interview-review-response-reaction.component.scss']
})
export class InterviewReviewResponseReactionComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public responseReactionForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.responseReactionForm = this.fb.group({
      feltAwkward: [null ],
      allowPause: [null ],
      sensitiveColResponses: [null ],
      focusOnStrengths: [null ],
      offTopic: [null ],
      scoreResponses: [null ],
      notesResponses: [null ],
    });
  }


  get userType() {
    return this.authService.getUserType();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewReviewResponseReaction(this.interviewID).subscribe(information => {
        if (information) {
          this.responseReactionForm = this.fb.group({
            feltAwkward: [information.feltAwkward ],
            allowPause: [information.allowPause ],
            sensitiveColResponses: [information.sensitiveColResponses ],
            focusOnStrengths: [information.focusOnStrengths ],
            offTopic: [information.offTopic ],
            scoreResponses: [information.scoreResponses ],
            notesResponses: [information.notesResponses ],
          });
        }
      });
    }
  }

  saveForm() {
    const feltAwkward = this.responseReactionForm.controls['feltAwkward'].value;
    const allowPause = this.responseReactionForm.controls['allowPause'].value;
    const sensitiveColResponses = this.responseReactionForm.controls['sensitiveColResponses'].value;
    const focusOnStrengths = this.responseReactionForm.controls['focusOnStrengths'].value;
    const offTopic = this.responseReactionForm.controls['offTopic'].value;
    const scoreResponses = this.responseReactionForm.controls['scoreResponses'].value;
    const notesResponses = this.responseReactionForm.controls['notesResponses'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID, feltAwkward, allowPause, sensitiveColResponses,
      focusOnStrengths, offTopic, scoreResponses, notesResponses
    };
    this.backendService.saveInterviewReviewResponseReaction(data)
      .subscribe(() => this.toastr.success('Responses & Reaction', 'Data saved successfully'))
    this.formSaved.emit();
  }


  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
