import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-interview-review-filming',
  templateUrl: './interview-review-filming.component.html',
  styleUrls: ['./interview-review-filming.component.scss']
})
export class InterviewReviewFilmingComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public filmingForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private authService: AuthService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.filmingForm = this.fb.group({
      wellFramed: [null ],
      lightSatisfactory: [null ],
      voiceClear: [null ],
      pointOfFocus: [null ],
      filmingInterviewLocation: [null ],
      scoreFilming: [null ],
      notesFilming: [null ],
    });
  }



  get userType() {
    return this.authService.getUserType();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewReviewFilming(this.interviewID).subscribe(information => {
        if (information) {
          this.filmingForm = this.fb.group({
            wellFramed: [information.wellFramed ],
            lightSatisfactory: [information.lightSatisfactory ],
            voiceClear: [information.voiceClear ],
            pointOfFocus: [information.pointOfFocus ],
            filmingIinterviewLocation: [information.filmingInterviewLocation ],
            scoreFilming: [information.scoreFilming ],
            notesFilming: [information.notesFilming ],
          });
        }
      });
    }
  }

  saveForm() {
    const wellFramed = this.filmingForm.controls['wellFramed'].value;
    const lightSatisfactory = this.filmingForm.controls['lightSatisfactory'].value;
    const voiceClear = this.filmingForm.controls['voiceClear'].value;
    const pointOfFocus = this.filmingForm.controls['pointOfFocus'].value;
    const filmingInterviewLocation = this.filmingForm.controls['filmingInterviewLocation'].value;
    const scoreFilming = this.filmingForm.controls['scoreFilming'].value;
    const notesFilming = this.filmingForm.controls['notesFilming'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID, wellFramed, lightSatisfactory, voiceClear,
      pointOfFocus, filmingInterviewLocation, scoreFilming, notesFilming
    };
    this.backendService.saveInterviewReviewFilming(data)
      .subscribe(() => this.toastr.success('Filming', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
