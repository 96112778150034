import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-release-form-submission',
  templateUrl: './release-form-submission.component.html',
  styleUrls: ['./release-form-submission.component.scss']
})
export class ReleaseFormSubmissionComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public submissionForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private backendService: BackendService,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.submissionForm = this.fb.group({
      interviewFormat: [ null ]
    });
  }

  get userType() {
    return this.authService.getUserType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getReleaseFormSubmission(this.interviewID).subscribe(form => {
        if (form) {
          this.submissionForm = this.fb.group({
            interviewFormat: [ form.interviewFormat ]
          });
        }
      });
    }
  }

  saveForm() {
    const interviewFormat = this.submissionForm.controls['interviewFormat'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      interviewFormat
    };
    this.backendService.saveReleaseFormSubmission(data)
      .subscribe(() => {
        this.toastr.success('Interview File Format', 'Data saved successfully');
        this.formSaved.emit();
      })
  }

}
