<div class="form-title mt-3 mb-3">
    Interviewee Residence Before Partition
</div>
<div class="form-data" [formGroup]="residenceBeforePartitionForm">
    <div class="form-group">
        <label for="houseNumberBeforePartition">House Number of residence before Partition</label>
        <input type="text" class="form-input" id="houseNumberBeforePartition" formControlName="houseNumberBeforePartition">
    </div>
    <div class="form-group">
        <label for="streetBeforePartition">Street of residence before Partition</label>
        <input type="text" class="form-input" id="streetBeforePartition"  formControlName="streetBeforePartition">
    </div>
    <div class="form-group">
        <label for="cityBeforePartition" class="required">City/Village of residence before Partition</label>
        <input type="text" class="form-input" id="cityBeforePartition" formControlName="cityBeforePartition">
    </div>
    <div class="form-group">
        <label for="stateBeforePartition" class="required">State/Province/Division of residence before Partition</label>
        <input type="text" class="form-input" id="stateBeforePartition"  formControlName="stateBeforePartition">
    </div>
    <div class="form-group">
        <label for="districtBeforePartition">District/Tehsil/Jilla of residence before Partition</label>
        <input type="text" class="form-input" id="districtBeforePartition" formControlName="districtBeforePartition">
    </div>
    <div class="form-group">
        <label for="postalCodeBeforePartition">Zip/Postal Code of residence before Partition</label>
        <input type="text" class="form-input" id="postalCodeBeforePartition" formControlName="postalCodeBeforePartition">
    </div>
    <div class="form-group">
        <label for="countryBeforePartition" class="required">Country of residence before Partition</label>
        <input type="text" class="form-input" id="countryBeforePartition"  formControlName="countryBeforePartition">
    </div>
    <div class="form-group">
        <label for="villageBeforePartition">For Village/Town: Precise details for residence before Partition</label>
        <input type="text" class="form-input" id="villageBeforePartition"  formControlName="villageBeforePartition">
    </div>
    <p style="margin-top:33px">
        For latitude and longitude coordinates, write the information according to this format: <b>##.####</b>. Do not input North (N) or South (S), East (E), or West (W) letters, or degree sign.
        <br><br>
        <span style="text-decoration:underline">Example:</span>
        <br>
        Berkeley, California, USA: <b>37.8717</b> (latitude) and <b>-122.2728</b> (longitude)
        <br>
        Lahore, Punjab, Pakistan: <b>31.5204</b> (latitude) and <b>74.3587</b> (longitude)
        <br>
        Perth, Western Australia, Australia: <b>-31.9505</b> (latitude) and <b>115.8605</b> (longitude)
      </p>
    <div class="form-group">
        <label for="migratedFromAddress" class="required">Migrated From (Village/City, State, Country)</label>
        <input type="text" class="form-input" id="migratedFromAddress" formControlName="migratedFromAddress">
      </div>
    <div class="form-group">
        <label for="latitude1" class="required">Latitude of residence before Partition</label>
        <input type="text" class="form-input" id="latitude1"  formControlName="latitude1">
    </div>
    <!--<div class="form-group" *ngIf="userType === 'admin'">
        <label for="latitude2" class="required">Latitude</label>
        <input type="text" class="form-input" id="latitude2"  formControlName="latitude2">
    </div>-->
    <div class="form-group">
        <label for="longitude1" class="required">Longitude of residence before Partition</label>
        <input type="text" class="form-input" id="longitude1"  formControlName="longitude1">
    </div>
    <!--<div class="form-group" *ngIf="userType === 'admin'">
        <label for="longitude2" class="required">Longitude</label>
        <input type="text" class="form-input" id="longitude2"  formControlName="longitude2">
    </div>-->
</div>
<div class="mt-5 text-center">
    <button  *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'"  class="save-button" (click)="saveForm()">Save</button>
</div>
